import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../../components/ExternalLink';
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "wave-motifs",
      "style": {
        "position": "relative"
      }
    }}>{`Wave motifs`}<a parentName="h1" {...{
        "href": "#wave-motifs",
        "aria-label": "wave motifs permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Wave motifs, also known as koros, are graphic elements that are inspired by the City of Helsinki crest. The goal of using a koro shape is to bring visual interest to the identity.
    </LeadParagraph>
    <p>{`See the `}<InternalLink href="/components/koros" mdxType="InternalLink">{`Koros component documentation`}</InternalLink>{` for instructions on using Koros in implementation.`}</p>
    <h2 {...{
      "id": "visual-principles",
      "style": {
        "position": "relative"
      }
    }}>{`Visual principles`}<a parentName="h2" {...{
        "href": "#visual-principles",
        "aria-label": "visual principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "shapes",
      "style": {
        "position": "relative"
      }
    }}>{`Shapes`}<a parentName="h3" {...{
        "href": "#shapes",
        "aria-label": "shapes permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Wave motifs are available in six (6) different shapes:`}</p>
    <ul>
      <li parentName="ul">{`Calm (`}<span lang="fi">{`Tyyni`}</span>{`)`}</li>
      <li parentName="ul">{`Basic (`}<span lang="fi">{`Perus`}</span>{`)`}</li>
      <li parentName="ul">{`Pulse (`}<span lang="fi">{`Pulssi`}</span>{`)`}</li>
      <li parentName="ul">{`Beat (`}<span lang="fi">{`Syke`}</span>{`)`}</li>
      <li parentName="ul">{`Storm (`}<span lang="fi">{`Tyrsky`}</span>{`)`}</li>
      <li parentName="ul">{`Wave (`}<span lang="fi">{`Värinä`}</span>{`)`}</li>
    </ul>
    <Image src="/images/foundation/visual-assets/wave-motifs/koro-shapes@2x.png" alt="All six (6) available koro shapes" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "720px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <h3 {...{
      "id": "size",
      "style": {
        "position": "relative"
      }
    }}>{`Size`}<a parentName="h3" {...{
        "href": "#size",
        "aria-label": "size permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Wave motifs can be used in any size if at least two wave tips are visible per surface. However, it is recommended to use one of two HDS provided sizes; Default or Dense.`}</p>
    <Image src="/images/foundation/visual-assets/wave-motifs/koro-sizes@2x.png" alt="Two recommended Koro sizes; Default and Dense" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "720px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <h3 {...{
      "id": "angle",
      "style": {
        "position": "relative"
      }
    }}>{`Angle`}<a parentName="h3" {...{
        "href": "#angle",
        "aria-label": "angle permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Wave motifs can be used in any 0, 90, and 45 degree angles.`}</p>
    <Image src="/images/foundation/visual-assets/wave-motifs/allowed-koro-angles@2x.png" alt="Koros are allowed to be rotated in 45 degree increments" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "720px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <h3 {...{
      "id": "colour",
      "style": {
        "position": "relative"
      }
    }}>{`Colour`}<a parentName="h3" {...{
        "href": "#colour",
        "aria-label": "colour permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Wave motif colour selection follows these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Only one color per one wave motif shape (no gradients or multiple colours)`}</li>
      <li parentName="ul">{`Any of the City of Helsinki Primary colours can be used as well as Black and White `}
        <ul parentName="li">
          <li parentName="ul">{`Grayscale colours are not recommended`}
            <ul parentName="li">
              <li parentName="ul">{`If you need a gray colour, use the brand colour Hopea (Silver) instead`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Secondary and Accent colours of the palette must not be used`}</li>
        </ul>
      </li>
    </ul>
    <Image src="/images/foundation/visual-assets/wave-motifs/koro-colour-usage@2x.png" alt="Dos and Don'ts of Koro colour usage" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "720px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <p>{`Additionally, a Koro shape can also act as a mask for an image.`}</p>
    <Image src="/images/foundation/visual-assets/wave-motifs/koro-with-an-image@2x.png" alt="Koro shape used as a mask for an image" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "720px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "where-to-use",
      "style": {
        "position": "relative"
      }
    }}>{`Where to use`}<a parentName="h3" {...{
        "href": "#where-to-use",
        "aria-label": "where to use permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Wave motifs are used to visually divide surfaces from each other.`}</p>
    <Image src="/images/foundation/visual-assets/wave-motifs/koro-usage-edge-of-section@2x.png" alt="Using Koros to separate sections from each other" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "720px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <p>{`However, pay attention to the contrast between section backgrounds. Be cautious when using stark colours that have a large contrast ratio between them.`}</p>
    <Image src="/images/foundation/visual-assets/wave-motifs/koro-usage-in-content-sections@2x.png" alt="Pay attention to colour contrasts when using Koros to separate sections" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "720px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <p>{`One common use case for Koro shapes is to use them to visually separate elements from each other. For example, to separate a text area from an image.`}</p>
    <Image src="/images/foundation/visual-assets/wave-motifs/koro-usage-visually-separate-elements@2x.png" alt="Koros can be used to visually separate elements from each other, e.g. text areas from images" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "720px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <p>{`Koro shapes should not be used as an accent element. They are purely meant for visual purposes.`}</p>
    <Image src="/images/foundation/visual-assets/wave-motifs/koro-accent-usage@2x.png" alt="Koro shapes should not be used to accent elements, e.g. to indicate an active state" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "720px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <h3 {...{
      "id": "which-size-and-shape-to-use",
      "style": {
        "position": "relative"
      }
    }}>{`Which size and shape to use`}<a parentName="h3" {...{
        "href": "#which-size-and-shape-to-use",
        "aria-label": "which size and shape to use permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Follow these rules when using wave motifs:`}</p>
    <ul>
      <li parentName="ul">{`At least 2 full-wave motif “tips” visible per surface`}</li>
      <li parentName="ul">{`It is recommended to use HDS provided sizes (Default and Dense)`}
        <ul parentName="li">
          <li parentName="ul">{`It is recommended to choose one size and use it throughout the whole service`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Shape can be freely chosen (from the premade 6 shapes) by the service`}
        <ul parentName="li">
          <li parentName="ul">{`It is recommended to choose one shape and use it throughout the whole service`}</li>
        </ul>
      </li>
    </ul>
    <Image src="/images/foundation/visual-assets/wave-motifs/koro-sizes-and-aligment@2x.png" alt="Dos and Don'ts of Koro shape and alignment" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "720px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <p>{`Be cautious when mixing Koro shapes and sizes. It is a good practice using one shape and size across the whole service or page.`}</p>
    <Image src="/images/foundation/visual-assets/wave-motifs/mixing-koro-shapes-and-sizes@2x.png" alt="Be cautious when mixing Koro shapes and sizes." style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "720px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <h3 {...{
      "id": "hds-components-that-use-koros",
      "style": {
        "position": "relative"
      }
    }}>{`HDS components that use Koros`}<a parentName="h3" {...{
        "href": "#hds-components-that-use-koros",
        "aria-label": "hds components that use koros permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`HDS includes a few components that have wave motif support built into them:`}</p>
    <ul>
      <li parentName="ul">
        <InternalLink href="/components/footer" mdxType="InternalLink">Footer</InternalLink>
      </li>
    </ul>
    <h2 {...{
      "id": "animation",
      "style": {
        "position": "relative"
      }
    }}>{`Animation`}<a parentName="h2" {...{
        "href": "#animation",
        "aria-label": "animation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>It is allowed to animate wave motifs. While this is not directly supported in the HDS Koro component, you can find more info and downloadable wave motif animation from the <ExternalLink href="https://brand.hel.fi/en/downloadable-animations/" mdxType="ExternalLink">Helsinki Brand website - Downloadable animations section</ExternalLink>.</p>
    <h2 {...{
      "id": "further-reading",
      "style": {
        "position": "relative"
      }
    }}>{`Further reading`}<a parentName="h2" {...{
        "href": "#further-reading",
        "aria-label": "further reading permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Please refer to the Helsinki Brand website to learn more about wave motifs and their usage:`}</p>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://brand.hel.fi/en/wave-motifs/" mdxType="ExternalLink">Helsinki Brand - Wave motifs</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://brand.hel.fi/en/surface-division/" mdxType="ExternalLink">Helsinki Brand - Surface division</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://brand.hel.fi/en/downloadable-animations/" mdxType="ExternalLink">Helsinki Brand - Downloadable animations</ExternalLink>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      